import { Box, Typography } from '@oresundsbron/bridge-ui';
import { cx } from 'class-variance-authority';
import { FC } from 'react';
import type { CustomerStatus as Status } from './utils';
import { Information } from '@oresundsbron/icons';
import { useTranslation } from 'next-i18next';

interface CustomerStatusProps {
  status?: Status;
}

const CustomerStatus: FC<CustomerStatusProps> = ({ status }) => {
  const { t } = useTranslation('account');

  if (!status) {
    return null;
  }

  return (
    <Box
      className={cx(
        'flex flex-col items-center gap-2 rounded px-3 py-2 text-center md:px-5 md:py-5',
        status.backgroundColor
      )}
    >
      <Information height="30px" width="30px" />
      <Typography intent="body">
        {t(`${status.translationKey}.title`)}
      </Typography>
      <Typography intent="body">
        {t(`${status.translationKey}.description`)}
      </Typography>
    </Box>
  );
};

export default CustomerStatus;
