import { Box, Button, Typography } from '@oresundsbron/bridge-ui';
import { Hamburger } from '@oresundsbron/icons';
import { FC, useState } from 'react';
import { useWindowSize } from '../../../modules/account/hooks/useWindowSize';
import MobileNavigation from '../MobileNavigation';

interface AccountBannerProps {
  title: string;
  navigationLabel: string;
  dashboard: boolean;
}

const AccountBanner: FC<AccountBannerProps> = ({ title, navigationLabel }) => {
  const [navigationOpen, setNavigationOpen] = useState(false);
  const { isDesktop } = useWindowSize();

  return (
    <>
      {isDesktop && (
        <>
          <Box className="col-span-full  row-span-full bg-primary-900 " />
          <Box className="col-start-2 row-span-full flex flex-wrap items-center justify-between py-6">
            <Typography
              as="h1"
              intent="headline"
              size="sm"
              className="flex-[1_0_50%] text-white  md:flex-1"
            >
              {title}
            </Typography>
            <Button
              intent="text"
              color="neutral"
              iconSize="lg"
              aria-label={navigationLabel}
              startIcon={<Hamburger className="fill-white" />}
              onClick={() => setNavigationOpen(true)}
            />
          </Box>
          <Box className="col-span-full block sm:hidden">
            <MobileNavigation
              isOpen={navigationOpen}
              onOpenChange={setNavigationOpen}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default AccountBanner;
