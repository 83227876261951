const baseURL = process.env.NEXT_PUBLIC_ANGULAR_PAY_BASE_URL as string;
const oresundsbronURL = process.env.NEXT_PUBLIC_ORESUND_URL as string;
// The links are temporarily hard coded because we are using a different domain for the pages the links takes the user.

export const links = {
  sv: {
    place: 'stallen',
    order: 'bestall',
    help: 'hjalp',
    about: 'om',
    guides: 'hjalp/guide',
    licensPlate: 'hjalp/guide/betaling-med-registreringsnummer',
    payWithBizz: 'hjalp/guide/betalning-med-bizz',
    termsAndConditions: 'om/villkor-och-regler',
    faq: 'hjalp/faq',
    orderContract: 'bestall',
    privacyPolicy: 'om/villkor-och-regler/personuppgiftspolicy',
    cookiePolicy: 'om/villkor-och-regler/cookie-policy',
    customerService: 'hjalp/kontakt',
    whistleBlower: 'hjalp/whistleblower',
    kmToll: 'vagavgift',
    prePaid: 'vagskatt-danmark/ruttbiljett',
    privatePayTerms:
      'https://assets.ctfassets.net/nvd3zqha2yv3/5Zlvr5TZAOyy8xxQNMcxSt/5057c90316f81b12dda650e1e22364cb/8573_OresundPay_Aftalevilkar_SE2.pdf',
    businessPayTerms:
      'https://assets.ctfassets.net/nvd3zqha2yv3/2XYUULaLgWBzd34QC3lLtt/92d542c9e227f26f05c48fad4902344c/8573_OresundPay_Business_SE2.pdf',
  },
  en: {
    place: 'places',
    order: 'order',
    help: 'help',
    about: 'about',
    guides: 'help/guide',
    licensPlate: 'help/guide/payment-by-number-plate',
    payWithBizz: 'help/guide/paying-with-bizz',
    termsAndConditions: 'about/terms-and-conditions',
    faq: 'help/faq',
    orderContract: 'order',
    privacyPolicy: 'about/terms-and-conditions/personal-data-policy',
    cookiePolicy: 'about/terms-and-conditions/cookie-policy',
    customerService: 'help/contact',
    whistleBlower: 'help/whistleblower',
    kmToll: 'roadtoll',
    prePaid: 'roadtax-denmark/routeticket',
    privatePayTerms:
      'https://assets.ctfassets.net/nvd3zqha2yv3/5Zlvr5TZAOyy8xxQNMcxSt/3c7d44c95048dd501177cfb484979e27/8573_OresundPay_Aftalevilkar_UK2.pdf',
    businessPayTerms:
      'https://assets.ctfassets.net/nvd3zqha2yv3/2XYUULaLgWBzd34QC3lLtt/19ce0287810a50c05c43dffef069599e/8573_OresundPay_Business_UK2.pdf',
  },
  da: {
    place: 'steder',
    order: 'bestil',
    help: 'hjaelp',
    about: 'om',
    guides: 'hjaelp/guide',
    licensPlate: 'hjaelp/guide/betaling-med-nummerplade',
    payWithBizz: 'hjaelp/guide/betaling-med-bizz',
    termsAndConditions: 'om/vilkaar-og-betingelser',
    faq: 'hjaelp/faq',
    orderContract: 'bestil',
    privacyPolicy: 'om/vilkaar-og-betingelser/persondatapolitik',
    cookiePolicy: 'om/vilkaar-og-betingelser/cookie-policy',
    customerService: 'hjaelp/kontakt',
    whistleBlower: 'hjaelp/whistleblower',
    kmToll: 'vejafgift',
    prePaid: 'vejskat-danmark/rutebillet',
    privatePayTerms:
      'https://assets.ctfassets.net/nvd3zqha2yv3/5Zlvr5TZAOyy8xxQNMcxSt/e8e3b52314424ecbc8ab46403694e1ee/8573_OresundPay_Aftalevilkar_DK2.pdf',
    businessPayTerms:
      'https://assets.ctfassets.net/nvd3zqha2yv3/2XYUULaLgWBzd34QC3lLtt/18d7353a09351f5b4c3f19bd0a04e789/8573_OresundPay_Business_DK2.pdf',
  },
  no: {
    place: 'steder',
    order: 'bestill',
    help: 'hjelp',
    about: 'om',
    guides: 'hjelp/guide',
    licensPlate: 'hjelp/guide/betaling-med-registreringsnummer',
    payWithBizz: 'hjelp/guide/betaling-med-bizz',
    termsAndConditions: 'om/vilkar-og-betingelser',
    faq: 'hjelp/faq',
    orderContract: 'bestill',
    privacyPolicy: 'om/vilkar-og-betingelser/personopplysningspolicy',
    cookiePolicy: 'om/vilkar-og-betingelser/cookie-policy',
    customerService: 'hjelp/kontakt',
    whistleBlower: 'hjelp/whistleblowser',
    kmToll: 'veiavgift',
    prePaid: 'veiskatt-danmark/rutebillett',
    privatePayTerms:
      'https://assets.ctfassets.net/nvd3zqha2yv3/5Zlvr5TZAOyy8xxQNMcxSt/c9abce08d0b81605141084bcc798aca2/8573_OresundPay_Aftalevilkar_UK2.pdf',
    businessPayTerms:
      'https://assets.ctfassets.net/nvd3zqha2yv3/2XYUULaLgWBzd34QC3lLtt/7ac594a7f25dfe267960caa70d3f3827/8573_OresundPay_Business_UK2.pdf',
  },
};

export type PayLocale = keyof typeof links;
type LinkKey = keyof typeof links[PayLocale];

const bridgeLinks = {
  en: {
    goTerms: 'terms-info/agreement-oresundgo-oresundcommuter',
    businessTerms:
      'terms-info/agreement-oresundbusiness-oresundcommuter-for-business',
  },
  sv: {
    goTerms: 'avtalsvillkor-info/agreement-oresundgo-oresundcommuter',
    businessTerms:
      'avtalsvillkor-info/avtal-oresundbusiness-oresundpendlare-for-foretag',
  },
  da: {
    goTerms: 'aftalevilkar-info/fftalevilkar-oresundgo-oresundpendler',
    businessTerms:
      'aftalevilkar-info/aftale-oresundbusiness-oresundpendler-for-virksomheder',
  },
};

export type BridgeLocale = keyof typeof bridgeLinks;
type BridgeLinkKey = keyof typeof bridgeLinks[BridgeLocale];

export function getBridgeLinks(
  locale: BridgeLocale,
  key: BridgeLinkKey
): string {
  const path = bridgeLinks[locale][key];

  return `${oresundsbronURL}/${locale}/${path}`;
}
export function getLink(
  locale: PayLocale,
  key: LinkKey,
  skipBase?: boolean
): string {
  const path = links[locale][key];

  return skipBase ? path : `${baseURL}/${locale}/${path}`;
}
export { baseURL, oresundsbronURL };
