import TagManager from 'react-gtm-module';

type PurchaseItem =
  | { item_id: string; item_name?: string }
  | ({ item_id?: string; item_name: string } & {
      item_category: 'Private' | 'Business';
      item_variant:
        | 'OperatorSignup'
        | 'OperatorOnlineTicket'
        | 'OperatorMyAccount';
      price: string;
      quantity?: string;
      item_name: string;
    });

type Purchase = {
  transaction_id: string;
  value: string;
  currency: string;
  coupon?: string;
  items: PurchaseItem[];
};

const sendEvent = (event: Record<string, unknown>) =>
  TagManager.dataLayer({ dataLayer: event });

export const pageView = (url: string) =>
  sendEvent({
    event: 'PageView',
    action: url,
  });

export const purchase = (ecommerce: Purchase) =>
  sendEvent({
    event: 'purchase',
    ecommerce,
  });

export const resetPurchase = () =>
  sendEvent({
    ecommerce: null,
  });

export const subscribeToNewsLetterOnPurchase = (serviceType: string) => {
  sendEvent({
    event: 'newsletterSignup',
    pagePath: document.location.href.replace(location.hash, ''), // Exclude hash from url, else it will be http://www.oresundsbron.com/sv/pendlare/oresund-pendlare-business/registrera#termsOfAgreement
    serviceType: serviceType,
  });
};
