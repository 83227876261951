import { useCallback } from 'react';
import {
  BackendError,
  BackendQueryKeys,
  useTypeSafeMutation,
  useTypeSafeQuery,
} from '@oresundsbron/api';
import { CustomerInformation } from '@oresundsbron/api/src/backend/Customer';
import { useQueryClient } from '@tanstack/react-query';

type Response = { success: true } | { success: false; error: string };

interface UseCustomerInformationResult<T extends CustomerInformation> {
  customerInformation?: T;
  updateCustomer: (data: T) => Promise<Response>;
  isLoading: boolean;
  isUpdating: boolean;
  error?: string;
}

export function useCustomerInformation<
  T extends CustomerInformation
>(): UseCustomerInformationResult<T> {
  const queryClient = useQueryClient();
  const { data, isLoading } = useTypeSafeQuery(
    BackendQueryKeys.CustomerInformation(),
    ({ Customer }) => Customer.get,
    { suspense: true, staleTime: Infinity }
  );
  const { mutateAsync, isLoading: isUpdating } = useTypeSafeMutation(
    ['customerInformationMutation'],
    ({ Customer }) => Customer.updateCustomer,
    {
      onSuccess: () => queryClient.invalidateQueries(['account']),
    }
  );

  const updateCustomer = useCallback(
    async (d: T) => {
      try {
        await mutateAsync({ ...data, ...d });
        return { success: true } as Response;
      } catch (e) {
        const error = e as BackendError;
        return { success: false, error: error.error! };
      }
    },
    [data, mutateAsync]
  );

  return {
    customerInformation: data as T,
    updateCustomer,
    isLoading,
    isUpdating,
  };
}
