import { pipe } from 'fp-ts/lib/function';
import { getOrElse } from 'fp-ts/lib/Option';
import { lookup } from 'fp-ts/lib/Record';
import { useMemo } from 'react';

const localeCodes = {
  sv: 'sv-SE',
  da: 'da-DK',
  en: 'en-GB',
  de: 'de-DE',
};

const getLocaleCode = (locale: string) =>
  pipe(
    localeCodes,
    lookup(locale),
    getOrElse(() => 'en-GB')
  );

const useLocaleCode = (locale: string) => {
  return useMemo(() => getLocaleCode(locale), [locale]);
};

export default useLocaleCode;
