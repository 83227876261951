import {
  AppInsightsContext,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import { FC, PropsWithChildren } from 'react';

const reactPlugin = new ReactPlugin();

const AzureAppInsights: FC<PropsWithChildren> = ({ children }) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
};

export default AzureAppInsights;
